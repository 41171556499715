"use client";

import { useUser } from "@auth0/nextjs-auth0/client";
import { usePathname, useRouter } from "next/navigation";
import { createContext, FC, useContext, useEffect, useState } from "react";
import useSWR from "swr";
import { NishaaniUser } from "../typings/NishaaniUser";
import {
  CreateProfileDto,
  Profile,
  UpdateProfileDto,
} from "../typings/Profile";

type NishaaniUserContextType = {
  user: NishaaniUser | null;
  currentProfile: Profile | null;
  error: any;
  isLoading: boolean;
  setCurrentProfile: (newProfile: Profile) => void;
  unsetCurrentProfile: () => void;
  handleProfilesChange: (
    profile: CreateProfileDto | UpdateProfileDto,
    type: "create" | "update",
  ) => Promise<void>;
};

const NishaaniUserContext = createContext<NishaaniUserContextType | undefined>(
  undefined,
);

const LS_PROFILE_ID_KEY = "nishaani-profile-id";

const profileChangeConfig = {
  create: {
    method: "POST",
    url: "/api/nishaani/users/me/profiles/create",
  },
  update: {
    method: "PUT",
    url: "/api/nishaani/users/me/profiles/update",
  },
};

export function getProfileFromUser(
  user: NishaaniUser | null,
  profileId?: string,
): Profile | null {
  if (!user || typeof window === "undefined") {
    return null;
  }

  const currentProfileId = localStorage.getItem(LS_PROFILE_ID_KEY);
  return Array.isArray(user?.profiles) && (profileId || currentProfileId)
    ? user.profiles.find(({ id }) =>
        profileId ? id === profileId : id === currentProfileId,
      ) || null
    : null;
}

function fetcher(url) {
  return fetch(url).then((r) => r.json());
}

export const NishaaniUserProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const router = useRouter();
  const pathname = usePathname();
  const [user, setUser] = useState<NishaaniUser | null>(null);
  const [profile, setProfile] = useState<Profile | null>(null);

  const { data, error, mutate } = useSWR("/api/auth/me", fetcher, {
    revalidateIfStale: false,
  });

  const { checkSession } = useUser();

  useEffect(() => {
    const user = !data?.error ? data : null;
    const profile = getProfileFromUser(user);

    setUser(user);

    if (
      user &&
      !profile &&
      ["/choose-profile", "/account/select-plan"].includes(pathname as string)
    ) {
      router.replace("/choose-profile");
    }

    if (profile) {
      setProfile(profile);
    }
  }, [data, pathname, router]);

  const setCurrentProfile = (newProfile: Profile) => {
    if (newProfile) {
      setProfile(newProfile);
      if (typeof window !== "undefined") {
        localStorage.setItem(LS_PROFILE_ID_KEY, newProfile.id);
      }
    }
  };

  const unsetCurrentProfile = () => {
    setProfile(null);
    if (typeof window !== "undefined") {
      localStorage.removeItem(LS_PROFILE_ID_KEY);
    }
  };

  async function handleProfilesChange(
    profile: CreateProfileDto | UpdateProfileDto,
    type: "create" | "update",
  ) {
    const { url, method } = profileChangeConfig[type];

    try {
      await fetch(url, {
        method: method,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(profile),
      });

      // Execute a refetch of the Auth0 profile to be cached & update the cookie
      // These two lines are very important for nextjs-auth0 to update the user session
      // It is equally important that they are called from the frontend or else the auth0 cookie will not update properly
      await fetch("/api/auth/me");
      await checkSession();

      // Trigger a revalidation (refetch) to make sure our local data at /api/auth/me is correct and up-to-date
      mutate();
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  }

  const providerValue = {
    user,
    currentProfile: profile,
    error,
    isLoading: !data && !error,
    setCurrentProfile,
    unsetCurrentProfile,
    handleProfilesChange,
  };

  return (
    <NishaaniUserContext.Provider value={providerValue}>
      {children}
    </NishaaniUserContext.Provider>
  );
};

export const useNishaaniUser = () => {
  const context = useContext(NishaaniUserContext);
  if (context === undefined) {
    throw new Error(
      "useNishaaniUser must be used within a NishaaniUserProvider",
    );
  }
  return context;
};
